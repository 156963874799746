import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./detail.css";

import logo from '../images/common/logo.png'
import Motif_left from '../images/service/motif_left.png'
import Motif_right from '../images/service/motif_right.png'

import Select from '../components/service/select_member';
import Policy from '../components/common/policy';

const Detail1 = () => {
  const [ visibleoverlay, setVisibleoverlay ] = useState(false);

  const handleSelectMember = () => {
    setVisibleoverlay(true);
  }

  const handleClose = () => {
    setVisibleoverlay(false);
  }

  return (
    <Layout>
      <SEO title="おなまえ公認コーチ" />
      <div className="detail-page">
        <div className="wrapper">
          <div className="head ff-min">
            <div className="catch">
              自分の中の
              <span>＼</span>
              私
              <span>／</span>
              を知る
            </div>
            <div className="sub-title">
              私分析学
            </div>
            <div className="title">
              おなまえメソッド
              <span className="registerd-sign">&#174;</span>
              セミナー
            </div>
            <div className="logo">
              <img src={logo} alt="ONAMAE METHOD" className="scale-to-fit" />
            </div>
          </div>
          <div className="detail-title">
            <p>Stage UP!</p>
            <ul>
              <li className="image">
                <img alt=" " src={Motif_left} className="scale-to-fit" />
              </li>
              <li className="text ff-min">
                <span>
                  おなまえ
                  <span className="registerd-sign">&#174;</span>
                  トレーナー
                </span>
                <span>
                  認定講座
                </span>
              </li>
              <li className="image">
                <img alt=" " src={Motif_right} className="scale-to-fit" />
              </li>
            </ul>
          </div>
          <div className="information ff-min">
            <h3>少人数グループのオンライン講座となります</h3>
            <ul>
              <li>1日集中コース 6時間 / 3回ゆっくりコース 2h /1回</li>
              <li>参加費275,000円（税込）<br />（スタートBOXをお持ちの方は38,500円引き）</li>
            </ul>
            <div className="benefits">
              <h4>-特典-</h4>
              <p>
                おなまえメソッド
                <span className="registerd-sign">&#174;</span>
                スタートBOXプレゼント
              </p>
              <p>
                授業を動画で毎回配信
              </p>
              <p>
                講座期間中にFaceBookグループでフォロー
              </p>
              <p>
                おなまえメソッド
                <span className="registerd-sign">&#174;</span>
                オンラインサロンご招待
              </p>
            </div>
          </div>
          <div className="read">
            <span>「名前」で本来の自分の姿で、生き方・考え方を深く学び</span>
            <span>伝える側にもなる養成講座</span>
          </div>
          <div className="read">
            <span>「本当の私」に戻り、自分の無限の可能性を確信するマインドを手に入れる</span>
            <span>
              考え方を「おなまえメソッド
              <span className="registerd-sign">&#174;</span>
              」を通して学ぶカリキュラム。
            </span>
            <span>
              「名前」と私たちの深く強い繋がりを「おなまえメソッド
              <span className="registerd-sign">&#174;</span>
              」視点で解説
            </span>
            <span>「宇宙の法則」「引き寄せの法則」などの本質に関しても触れながら</span>
            <span>「全部理想通り」の思考と行動が手にする事ができます。</span>
          </div>
          <div className="read">
            <span>&mdash;5つのカリキュラム&mdash;</span>
            <ul>
              <li>名前の表記で分かるキャラクター</li>
              <li>名前の影響力4つの仕組みを理解する</li>
              <li>3ワークでの分析解読法</li>
              <li>名前が引き寄せる「理想」の現実が手に入る仕組み</li>
              <li>
                おなまえメソッド
                <span className="registerd-sign">&#174;</span>
                で多くの人の人生を変える側になる
                <br />
                <p>
                  おなまえ
                  <span className="registerd-sign">&#174;</span>
                  入門トレーニング・おなまえ
                  <span className="registerd-sign">&#174;</span>
                  分析トレーニング開催可能となります。
                </p>
              </li>
            </ul>
          </div>
          <div className="read">
            <span>
              「仕事にしたり、他の人に伝える予定はないけれど、おなまえメソッド
               <span className="registerd-sign">&#174;</span>
               の考えを
            </span>
            <span>
              深く学びたい」「未来のことはまだわからないけれど、とりあえず挑戦してみたい」
            </span>
            <span>
              という方も大歓迎です。
            </span>
          </div>
          <Policy />
          <div className="btn-holder">
            <span role="button" tabIndex={0} onClick={handleSelectMember} onKeyDown={handleSelectMember}>講師を選択する</span>
          </div>
        </div>
        <div className="wrapper">
          <div className="detail-title">
            <p>Stage UP!</p>
            <ul>
              <li className="image">
                <img alt=" " src={Motif_left} className="scale-to-fit" />
              </li>
              <li className="text ff-min">
                <span>
                  おなまえ
                  <span className="registerd-sign">&#174;</span>
                </span>
                <span>
                  分析士養成講座
                </span>
              </li>
              <li className="image">
                <img alt=" " src={Motif_right} className="scale-to-fit" />
              </li>
            </ul>
          </div>
          <div className="information ff-min">
            <h3>少人数グループのオンライン講座となります</h3>
            <ul>
              <li>1日集中コース 4時間 / 2回ゆっくりコース 2h /1回</li>
              <li>参加費198,000円（税込）<br />（スタートBOXをお持ちの方は38,500円引き）</li>
            </ul>
            <div className="benefits">
              <h4>-特典-</h4>
              <p>
                おなまえメソッド
                <span className="registerd-sign">&#174;</span>
                スタートBOXプレゼント
              </p>
              <p>
                授業を動画で毎回配信
              </p>
              <p>
                講座期間中にFaceBookグループでフォロー
              </p>
              <p>
                おなまえメソッド
                <span className="registerd-sign">&#174;</span>
                オンラインサロンご招待
              </p>
            </div>
          </div>
          <div className="read">
            <span>
              おなまえ
              <span className="registerd-sign">&#174;</span>
              分析士
            </span>
          </div>
          <div className="read">
            <span>「名前」に秘められた暗号を分析し、</span>
            <span>ネームプロファイル（鑑定）手法を取得する養成講座</span>
          </div>
          <div className="read">
            <span>「名前」１つで自分自身のことを深く紐解いていく手法</span>
            <span>
              おなまえ
              <span className="registerd-sign">&#174;</span>
              アナグラム上級とネームプロファイリングを「おなまえメソッド
              <span className="registerd-sign">&#174;</span>
              」を通して学びます。
            </span>
          </div>
          <div className="read">
            <span>「名前」でのカウンセリング・ヒーリング・コーチング法を取得していきます。</span>
            <span>コンサルティングやセッションをしたことがない初めての方でも、</span>
            <span>活動していただきやすいようにカリキュラムを組んでおりますので、</span>
            <span>ご安心ください。</span>
          </div>
          <div className="read">
            <span>「”私”はこんなもんじゃない！！！」無限の可能性で人生を切り開いていく</span>
            <span>多くの人にルートに光を照らしていきます。</span>
          </div>
          <div className="read">
            <span>
              ※おなまえ
              <span className="registerd-sign">&#174;</span>
              分析鑑定開催可能となります。
            </span>
          </div>
          <div className="read">
            <span>「仕事にしたり、他の人に伝える予定はないけれど、</span>
            <span>
              おなまえメソッド
              <span className="registerd-sign">&#174;</span>
              の考えを深く学びたい」
            </span>
            <span>「未来のことはまだわからないけれど、とりあえず挑戦してみたい」という方も大歓迎です。</span>
          </div>
          <Policy />
          <div className="btn-holder">
            <span role="button" tabIndex={0} onClick={handleSelectMember} onKeyDown={handleSelectMember}>講師を選択する</span>
          </div>
        </div>
      </div>
      { visibleoverlay ?
        <Select close={handleClose} subject='講座のお申し込み' isNotExistCaptain={true} />
        :
        null
      }
    </Layout>
  )
}

export default Detail1
